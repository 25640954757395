

















































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props, { root }) {
    const state = reactive({
      registrationDate: {
        from: "",
        to: "",
      },
      fees: [],
      loading: false,
    });

    const fetchFees = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/registration-fee/group/select`)
        .then(({ data }) => {
          state.fees = data
            .map((el: any) => ({
              fees: el.registrationFees.map((item: any) => ({
                id: item.id,
                name: item.name,
                groupName: el.name,
              })),
            }))
            .map((el: any) => el.fees)
            .flat();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.fees = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchFees);

    const selectAllFees = () => {
      props.value.registrationType.values = state.fees.map((el: any) => el.id);
    };

    const deleteItem = (item: any) => {
      props.value.registrationType.values = props.value.registrationType.values.filter(
        (el: any) => el !== item
      );
    };

    watch(
      () => state.registrationDate,
      () => {
        if (state.registrationDate.from || state.registrationDate.to) {
          props.value.registrationDate.values = [
            state.registrationDate.from
              ? state.registrationDate.from
              : undefined,
            state.registrationDate.to ? state.registrationDate.to : undefined,
          ];
        } else props.value.registrationDate.values = [];
      },
      { deep: true }
    );

    watch(
      () => props.value.registrationType.operation,
      () => {
        if (props.value.registrationType.operation === null) {
          props.value.registrationType.operation = "in";
        }
      }
    );

    return { state, selectAllFees, deleteItem };
  },
});
